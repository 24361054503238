import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'underscore';
import { DeviceDetectorService } from 'ngx-device-detector';

import { StageListService } from '../../../services/stage-list.service';
import { Stage, StageCategory, StageStandings } from '../../../interfaces/stage.interface';

@Component({
    selector: 'app-stage-right-col',
    templateUrl: './stage-right-col.component.html',
})
export class StageRightColComponent implements OnChanges {
    @Input()
    public sportId: number | null = null;

    @Input()
    public sportName: string;

    @Input()
    public standingsStageId: number | null = null;

    public standingsData: StageStandings | null;

    @Input()
    public stageParticipantStageId: number;

    @Input()
    public stageParticipantParticipantId: number;

    public stageParticipantData: StageCategory[];

    public videoStage: Stage;

    /**
     * First data load
     */
    public first: boolean;

    public constructor(private stageListService: StageListService, public deviceService: DeviceDetectorService) {}

    public async ngOnChanges(change: SimpleChanges): Promise<void> {
        if (this.standingsStageId !== null && !_.isUndefined(change.standingsStageId)) {
            this.first = true;

            const data = await this.stageListService.getStageStandings(this.standingsStageId);

            data.subscribe((standings) => {
                this.standingsData = standings;
            });
        }

        if (
            this.stageParticipantStageId !== null &&
            this.stageParticipantParticipantId !== null &&
            !_.isUndefined(this.stageParticipantStageId) &&
            !_.isUndefined(this.stageParticipantParticipantId)
        ) {
            if (
                !_.isUndefined(change.stageParticipantStageId) ||
                !_.isUndefined(change.stageParticipantParticipantId)
            ) {
                const data = await this.stageListService.getStageEventParticipant(
                    this.stageParticipantStageId,
                    this.stageParticipantParticipantId,
                );

                data.subscribe((dataVal) => {
                    this.stageParticipantData = dataVal;
                });
            }
        }

        this.stageListService.stageVideoOpener$.subscribe((stage) => {
            this.videoStage = stage;
        });
    }
}
