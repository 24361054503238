/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Match list page
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, OnDestroy, HostListener, AfterViewChecked } from '@angular/core';
import { Subscription, timer, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import $ from 'src/app/shared/jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { DeviceDetectorService } from 'ngx-device-detector';
import SPORT_CONFIG from 'src/app/config/sport.config';
import { switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { CategoryInterface } from '../../../interfaces/category.interface';
import { MatchData } from '../../../interfaces/match-data.interface';
import { UtilsService } from '../../../services/utils.service';
import { ClientService } from '../../../services/client.service';
import { FavoriteService } from '../../../services/favorite.service';
import { LangService } from '../../../services/lang.service';
import { InfoService } from '../../../services/info.service';
import { StageListService } from '../../../services/stage-list.service';
import { StageCategory } from '../../../interfaces/stage.interface';


@Component({
    selector: 'app-stage-event',
    templateUrl: './stage-event.component.html',
})
export class StageEventComponent extends PageMixin(BaseComponent) implements OnInit, OnDestroy, AfterViewChecked {
    public sportId: number;

    public sportName: string;

    public data: StageCategory[];

    /**
     * Data for each category
     * @param protectedpage [description]
     * @param router        [description]
     * @param matchList     [description]
     */
    public categoryData: { [prop: number]: MatchData[] };

    public categoryGroupData: { [prop: number]: CategoryInterface[] };

    public stageId: number | null = null;

    public stageParticipantStageId: number;

    public stageParticipantParticipantId: number;

    public parentClass: Record<string, boolean> = { 'col-lg-12': false, 'col-lg-8': true };

    public matchClass: Record<string, boolean> = { 'col-lg-4': true, 'd-lg-block': true };

    public tracker: boolean = false;

    public detailCaption: string = '';

    public date: string | null = null;

    public paramDate: string | any | null = null;

    public alphaAvailLetters: string[] = [];

    public alphaFilter: { star: boolean; alpha: string[] };

    private subscriber: Subscription;

    private subscriber2: Subscription;

    private subscriberReload: Subscription;

    private subscriberTab: Subscription;

    public type: string;

    public slice: number = 1000;

    public categoryGroup: CategoryInterface[] = [];

    public loaded = false;

    /**
     * Switch group category on/off
     */
    public isGroupCategory: boolean = false;

    /**
     * number of sport aside menu
     */
    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * First data load
     */
    public first: boolean;

    public firstDefault: boolean;

    private firstInterval: number;

    private openAll: boolean | null = null;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        private stageList: StageListService,
        private favorite: FavoriteService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
    ) {
        super(page, title, meta, route, info);
    }

    @HostListener('window:scroll', [])
    private onWindowScroll(): void {
        const val = window.scrollY;
        this.storage.set('APP_LAST_SCROLL', val);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.router.paramMap.subscribe((params): void => {
            $('[data-toggle="tooltip"]').tooltip('hide');
            this.loaded = false;

            this.first = true;

            if (params.has('event')) {
                this.stageId = +params.get('event')!;
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport')!);
                this.ngOnDestroy();
                this.load();
            } else {
                this.error();
            }
        });

        this.favorite.onToggleTournament.subscribe((): void => {
            this.load(true);
        });

        this.stageList.stageParticipantOpener$.subscribe((data) => {
            this.loadStageParticipant(data);
        });
    }

    public ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    public ngOnDestroy(): void {
        if (this.subscriberTab instanceof Subscription) {
            this.subscriberTab.unsubscribe();
        }

        this.destroyAll();
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): void {
        this.translate.get('web.title_tournament').subscribe((): void => {
            const event = this.data[0]
                ? `${this.data[0].category_description} - ${this.data[0].eventStage_description}`
                : '';
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const trans = this.translate.instant('web.title_tournament');
            const desc = this.translate.instant('web.description_tournament');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${event} - ${trans} | ${client}`,
                    description: `${event} - ${sport} ${desc}`,
                },
                true,
            );
        });
    }

    /**
     * Load data
     */
    private loader(): void {
        this.destroyAll();

        this.subscriberReload = timer(0, environment.stageListReloadTime)
            .pipe(switchMap((t): any => of(t)))
            .subscribe((): Promise<void> => this.load());
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Destroy all subscribers
     * @return {void}
     */
    private destroyAll(): void {
        this.data = [];
        clearInterval(this.firstInterval);
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    private destroyPart(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    public trackByFn(index: number, item: Record<string, any>): number | null {
        if (_.isUndefined(item)) {
            return null;
        }
        return item.id;
    }

    /**
     * Check if data for suboturnamen texists
     * @param  {number} subTournamentId
     * @return    {MatchData[]}
     */
    public getData(subTournamentId: number): MatchData[] {
        return (this.categoryData && this.categoryData[subTournamentId]) || [];
    }

    /**
     * Event fired when some match is selected/clicked
     * @event MatchListComponent#loadMatch
     * @param  stage
     * @param  {boolean} updateCaption
     * @return {void}
     */
    public loadStage(stage: any, updateCaption: boolean = true): void {
        if (updateCaption) {
            this.detailCaption = '';
        }
        if (this.stageId != null && this.stageId === stage.stageId) {
            return;
        }
        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        this.matchClass['d-lg-block'] = true;
        // this.stageId = stage.stageId; //TODO i do comment but iam not sure, right column disapear
        this.tracker = false;
        // if (_.has(match, "tracker")) {
        //     this.tracker = match.tracker as boolean;
        // }
    }

    public loadStageParticipant(data: any): void {
        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        this.matchClass['d-lg-block'] = true;
        this.stageParticipantStageId = data.stageId;
        this.stageParticipantParticipantId = data.participantId;
    }

    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (_.isUndefined(this.data[0])) {
            this.stageId = null;
            this.parentClass['col-lg-12'] = true;
            this.parentClass['col-lg-8'] = false;
            this.matchClass['d-lg-block'] = false;
        } else {
            this.parentClass['col-lg-8'] = true;
            this.parentClass['col-lg-12'] = false;
            this.matchClass['d-lg-block'] = true;
            this.loadStage({ stageId: this.data[0].stages![0]!.category_id });
        }
    }

    /**
     * Check if there is no category data
     * @return [description]
     */
    public noCategory(): boolean {
        return !_.isArray(this.data);
    }

    private isCategoryOpen(subId: number): boolean {
        const openCategories = this.storage.get<any[]>(SPORT_CONFIG.categoryIndex) || [];

        const index = openCategories.findIndex((val): boolean => val === subId);

        return index !== -1;
    }

    public onOpenChange(event: any): void {
        this.openAll = event;
        this.load(true);
    }

    /**
     * Load tournament categories
     */
    protected async load(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        updateAll: boolean = false,
        data: any = null,
    ): Promise<any> {
        try {
            data = await this.stageList.getStageEvent(this.stageId);

            this.subscriber = data.subscribe(
                (val: StageCategory[]): void => {
                    this.data = val;

                    if (this.first) {
                        this.openDefault();
                    }
                    this.first = false;
                    this.loaded = true;

                    this.lang.getLang().then((): void => {
                        this.createTitle();
                    });

                    this.finished(true);
                },
                (): void => {
                    if (this.first) {
                        this.error(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error(true);
            }
        }
    }

    public isCategoryClose(categoryId: number): boolean {
        return this.stageList.isCategoryClose(categoryId);
    }
}
