<div
    class="table-sport__subhead d-flex align-items-center bg-light"
    (click)="openStage(stage.id)"
    [attr.data-toggle]="stage.participants && stage.participants.length !== 0 && stage.valueTypes ? 'collapse' : null"
    [attr.data-target]="'#collapse-stage-' + stage.id"
    role="button"
    [attr.aria-expanded]="stage.expand || isStageOpen(stage.id)"
    [class.collapsed]="!(stage.expand || isStageOpen(stage.id))"
    [attr.aria-controls]="'collapse-stage-' + stage.id"
>
    <img
        *ngIf="stage.parent_iso"
        src="/img/flags/1x1/{{ stage.parent_iso | lowercase }}.svg"
        alt=""
        width="20"
        class="mr-2 rounded-circle"
    />
    <img *ngIf="!stage.parent_iso" src="img/sports/{{ sportName }}.png" alt="" width="20" class="mr-2 rounded-circle" />
    <div class="table-sport__subhead__text mb-0 text-uppercase text-truncate">
        <ng-container *ngIf="deviceService.isMobile()">
            <span [class.status-cancelled]="stage.status === 'Cancelled'">
                {{ stage.parent_description }}
                <div class="text-muted mt-1">
                    <ng-container *ngIf="showDate">{{ stage.startDate | livDate : 'localDate' }}</ng-container>
                    {{ stage.description }} - {{ stage.startDate | livDate : 'localTime' }}
                </div>
            </span>
            <ng-container *ngIf="stage.status">
                <strong class="text-emph">{{ 'web.status-' + stage.status | lowercase | translate }}</strong>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="!deviceService.isMobile()">
            <span [class.status-cancelled]="stage.status === 'Cancelled'">
                <ng-container *ngIf="stage.parent_description">{{ stage.parent_description }} - </ng-container
                >{{ stage.description }} - <ng-container *ngIf="showDate">{{ stage.startDate | livDate : 'localDate' }}</ng-container>
                {{ stage.startDate | livDate : 'localTime' }}

            </span>
            <ng-container *ngIf="stage.status"
                >-
                <strong class="text-emph">{{ 'web.status-' + stage.status | lowercase | translate }}</strong>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="pageService.debug"> ({{ stage.id }}) </ng-container>

        <div class="text-muted mt-1">
            <span *ngIf="stage?.stageInfos?.weather">
                {{ 'web.weather' | translate }}:
                <strong>{{ 'web.' + stage.stageInfos.weather | lowercase | translate }}</strong> &nbsp;&nbsp;
            </span>
            <span *ngIf="stage?.stageInfos?.air_temperature">
                {{ 'web.air_temperature' | translate }}:
                <strong>{{ stage.stageInfos.air_temperature }}°C</strong> &nbsp;&nbsp;
            </span>
            <span *ngIf="stage?.stageInfos?.track_temperature">
                {{ 'web.track_temperature' | translate }}:
                <strong>{{ stage.stageInfos.track_temperature }}°C</strong> &nbsp;&nbsp;
            </span>
        </div>
    </div>
    <!-- <svg class="icon icon--12 mx-2"><use href="#icon-heart"/></svg> -->
    <div class="ml-auto">
        <ng-container *ngIf="pageService.debug">
            <a [href]="pageService.backofficeHost + '/stages/edit/' + stage.id" target="blank" title="Administration">
                <i class="fas fa-user-shield"></i
            ></a>
        </ng-container>
        <a
            *ngIf="stage.hasVideo && !deviceService.isMobile()"
            (click)="openVideo($event, stage)"
            data-toggle="tooltip"
            data-placement="top"
            data-cy="match-stream"
            title="{{ 'web.stream_channel' | translate }}"
        >
            <svg class="icon icon--12 icon--stream">
                <use xlink:href="#icon-stream" />
            </svg>
        </a>
        <a
            *ngIf="sportId && sportName && showEventDetailBtn"
            [routerLink]="['/page/stage/event', sportName + '-' + sportId, stage.parent_id]"
            (click)="closeAllStages()"
            class="ml-2 small text-reset app-stage-icon"
        >
            <svg class="icon icon--12 mr-2">
                <use href="#icon-table" />
            </svg>
            <ng-container *ngIf="!deviceService.isMobile()">{{ 'web.event-detail' | translate }}</ng-container>
        </a>
    </div>
</div>
<div
    *ngIf="
        (stage.expand || isStageOpen(stage.id)) &&
        stage.participants &&
        stage.participants.length !== 0 &&
        stage.valueTypes
    "
    class="collapse"
    [class.show]="stage.expand || isStageOpen(stage.id)"
    [attr.id]="'collapse-stage-' + stage.id"
>
    <div class="w-100 mt-3 mb-5">
        <app-stage-table
            *ngIf="stage.participants[1] && stage.participants[1].length > 0"
            [participants]="stage.participants[1]"
            [valueTypes]="stage.valueTypes.competitors"
            [stage]="stage"
            [sportName]="sportName"
            [sportId]="sportId"
        ></app-stage-table>

        <app-stage-table
            *ngIf="stage.participants[2] && stage.participants[2].length > 0"
            [participants]="stage.participants[2]"
            [valueTypes]="stage.valueTypes.teams"
            [stage]="stage"
            [sportName]="sportName"
            [sportId]="sportId"
        ></app-stage-table>
    </div>
</div>
