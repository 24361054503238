<ng-container *ngIf="standingsData; else elseBlock">
    <ng-container
        *ngIf="
            standingsData.participants &&
            standingsData.participants[1] &&
            standingsData.valueTypes &&
            standingsData.valueTypes.competitors
        "
    >
        <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
            <span class="font-weight-bold text-uppercase"
                >{{ 'web.drivers' | translate }} {{ standingsData.description }}</span
            >
            <!--    <a href="#" class="ml-auto small text-reset">-->
            <!--        <svg class="icon icon&#45;&#45;white icon&#45;&#45;12 mr-2">-->
            <!--            <use href="#icon-table"></use>-->
            <!--        </svg>-->
            <!--        Standings</a>-->
        </div>
        <div class="mb-3 bg-white">
            <!--<div class="mb-3 px-2 pb-4 bg-white">-->
            <div class="collapse show" id="collapse-drivers">
                <!--        <div class="row align-items-center mb-2">-->
                <!--            <div class="col-1 text-center">2.</div>-->
                <!--            <div class="col-7 d-flex flex-row align-items-center">-->
                <!--                <img src="img/logos/Federer.jpg" alt="Roger Federer" class="rounded-circle mr-2" height="24">-->
                <!--                <div><span class="font-weight-bold">Sebastian <span class="text-uppercase">Vettel</span></span><br><span-->
                <!--                    class="d-block text-secondary mt-n1 small">Ferrari</span></div>-->
                <!--            </div>-->
                <!--            <div class="col-2 text-center">5</div>-->
                <!--            <div class="col-2 text-center">197</div>-->
                <!--        </div>-->

                <div class="table-responsive">
                    <table class="table table-sm stage-table">
                        <thead>
                            <tr class="bg-light2">
                                <td class="small align-middle">#</td>
                                <td class="small align-middle">{{ 'web.driver' | translate }}</td>
                                <ng-container *ngFor="let valueType of standingsData.valueTypes.competitors">
                                    <td class="small align-middle text-center">
                                        {{ 'stagevaluetype.' + standingsData.category + '_' + valueType | translate }}
                                    </td>
                                </ng-container>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let participant of standingsData.participants[1]">
                                <td class="align-middle">
                                    <ng-container *ngIf="participant.position"
                                        >{{ participant.position }}.</ng-container
                                    >
                                </td>
                                <td class="text-nowrap align-middle">
                                    <a
                                        [routerLink]="[
                                            '/page/stage/participant',
                                            sportName + '-' + sportId,
                                            participant.id
                                        ]"
                                        class="text-reset"
                                        ><span class="font-weight-bold">{{ participant.name }}</span></a
                                    >
                                    <br />
                                    <a *ngIf="participant?.team_id" [routerLink]="['/page/stage/participant', sportName + '-' + sportId, participant.team_id]"
                                       class="small text-reset">{{ participant.team_name }}
                                    </a>
                                </td>
                                <ng-container *ngFor="let valueType of standingsData.valueTypes.competitors">
                                    <td class="align-middle text-center">
                                        {{ participant.values[valueType] }}
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container
        *ngIf="
            standingsData.participants &&
            standingsData.participants[2] &&
            standingsData.valueTypes &&
            standingsData.valueTypes.teams
        "
    >
        <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
            <span class="font-weight-bold text-uppercase"
                >{{ 'web.constructors' | translate }} {{ standingsData.description }}</span
            >
            <!--    <a href="#" class="ml-auto small text-reset">-->
            <!--        <svg class="icon icon&#45;&#45;white icon&#45;&#45;12 mr-2">-->
            <!--            <use href="#icon-table"></use>-->
            <!--        </svg>-->
            <!--        Standings</a>-->
        </div>
        <div class="mb-3 bg-white">
            <!--<div class="mb-3 px-2 pb-4 bg-white">-->
            <div class="collapse show" id="collapse-constructors">
                <div class="table-responsive">
                    <table class="table table-sm stage-table">
                        <thead>
                            <tr class="bg-light2">
                                <td>#</td>
                                <td>{{ 'web.team' | translate }}</td>
                                <ng-container *ngFor="let valueType of standingsData.valueTypes.teams">
                                    <td>{{ valueType }}</td>
                                </ng-container>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let participant of standingsData.participants[2]">
                                <td>
                                    <ng-container *ngIf="participant.position"
                                        >{{ participant.position }}.</ng-container
                                    >
                                </td>
                                <td class="text-nowrap">
                                    <!-- <img src="/img/default-avatar.png" alt="Roger Federer" class="rounded-circle mr-2" height="24">-->
                                    {{ participant.name }}
                                </td>
                                <ng-container *ngFor="let valueType of standingsData.valueTypes.teams">
                                    <td>
                                        {{ participant.values[valueType] }}
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #elseBlock>
    <ng-container *ngIf="!isUndefined">
        <app-page-loader></app-page-loader>
    </ng-container>
</ng-template>
