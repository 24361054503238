/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/**
 *  Match list page
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */
import { Component, OnInit, OnDestroy, HostListener, AfterViewChecked, QueryList, ViewChildren } from '@angular/core';
import { Subscription, timer, of, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { toPairs, isUndefined, isArray } from 'lodash-es';
import $ from 'src/app/shared/jquery';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { LiveDatePipe } from 'src/app/shared/pipes/live-datetime.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import SPORT_CONFIG from 'src/app/config/sport.config';
import { switchMap } from 'rxjs/operators';
import { StorageService } from 'src/app/services/storage.service';

import { StageComponent } from '@components/stage/stage/stage.component';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { TimeService } from '../../../services/time.service';
import { CategoryInterface } from '../../../interfaces/category.interface';
import { MatchData } from '../../../interfaces/match-data.interface';
import { UtilsService } from '../../../services/utils.service';
import { ClientService } from '../../../services/client.service';
import { FavoriteService } from '../../../services/favorite.service';
import { LangService } from '../../../services/lang.service';
import { InfoService } from '../../../services/info.service';
import { StageListService } from '../../../services/stage-list.service';
import { StageCategory, StageState } from '../../../interfaces/stage.interface';

declare const moment: any;


@Component({
    selector: 'app-stage-list',
    templateUrl: './stage-list.component.html',
})
export class StageListComponent extends PageMixin(BaseComponent) implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChildren(StageComponent) stageChildren!: QueryList<StageComponent>;

    public sportId: number;

    public sportName: string;

    public data: StageCategory[];

    /**
     * Data for each category
     * @param protectedpage [description]
     * @param router        [description]
     * @param matchList     [description]
     */
    public categoryData: { [prop: number]: MatchData[] };

    public categoryGroupData: { [prop: number]: CategoryInterface[] };

    private openCategories: string[] = [];

    public stageId: number | null = null;

    public stageParticipantStageId: number;

    public stageParticipantParticipantId: number;

    public parentClass: Record<string, boolean> = { 'col-lg-12': false, 'col-lg-8': true };

    public matchClass: Record<string, boolean> = { 'col-lg-4': true, 'd-lg-block': true };

    public tracker: boolean = false;

    public detailCaption: string = '';

    public date: string | null = null;

    public paramDate: string | any | null = null;

    public alphaAvailLetters: string[] = [];

    public alphaFilter?: { star: boolean; alpha: string[] };

    private subscriber: Subscription;

    private subscriber2: Subscription;

    private subscriberReload: Subscription;

    private subscriberTab: Subscription;

    private from: any;

    private to: any;

    public type: string;

    private subTournamets: number[] = [];

    public slice: number = 1000;

    public categoryGroup: CategoryInterface[] = [];

    /**
     * Switch group category on/off
     */
    public isGroupCategory: boolean = false;

    /**
     * number of sport aside menu
     */
    public asideLimit: number = SPORT_CONFIG.asideSportDefaultNum;

    /**
     * First data load
     */
    public first: boolean;

    public firstDefault: boolean;

    private firstInterval: number;

    private scrollNum: number = 0;

    private firstScroll: boolean = false;

    private openAll: boolean | null = null;

    public showSportMenu = false;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        private stageList: StageListService,
        private favorite: FavoriteService,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public deviceService: DeviceDetectorService,
        private storage: StorageService,
    ) {
        super(page, title, meta, route, info);
    }

    @HostListener('window:scroll', [])
    private onWindowScroll(): void {
        const val = window.scrollY;
        this.storage.set('APP_LAST_SCROLL', val);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.subscriberTab = this.tabSubject.subscribe((val: string): void => {
            if (val === 'visible') {
                this.load();
            }
        });

        this.router.paramMap.subscribe((params): void => {
            $('[data-toggle="tooltip"]').tooltip('hide');
            this.data = [];

            this.first = true;

            if (params.has('sport')) {
                [this.sportId, this.sportName] = UtilsService.parseSportUrl(params.get('sport')!);
                const date = params.has('date')
                    ? TimeService.getLocalTime(params.get('date')!, TimeService.dateFormat)
                    : moment();
                this.paramDate = params.has('date') ? params.get('date') : null;
                this.from = TimeService.startLocal(moment(date));
                this.to = TimeService.endLocal(moment(date));
            } else {
                this.error();
            }

            this.lang.getLang().then((): Promise<any> => this.createTitle());

            this.changeTab('all');
        });

        this.favorite.onToggleTournament.subscribe((): void => {
            this.load(true);
        });

        this.stageList.stageParticipantOpener$.subscribe((data) => {
            this.loadStageParticipant(data);
        });
    }


    public ngAfterViewChecked(): void {
        $('[data-toggle="tooltip"]').tooltip();

        if (this.first) {
            if (!this.deviceService.isMobile()) {
                setTimeout((): void => {
                    const stage = this.data[0]?.stages?.findIndex(s => s.status !== StageState.CANCELED) || 0;
                    const stageData = this.data[0]?.stages?.find(s => s.status !== StageState.CANCELED) || null;
                    const isOpen =
                        this.stageChildren.get(+stage)?.isStageOpen(this.stageChildren.get(+stage)?.stage.id!);
                    if (!isOpen) {
                        this.stageChildren.get(+stage)?.openStage(this.stageChildren.get(+stage)?.stage.id!);
                    }

                    if (stageData) {
                        const participant = toPairs(stageData!.participants)[0]?.[1]?.[0];
                        const categoryId = this.stageChildren.get(+stage)?.stage.category_id || 0;
                        const parentId = this.stageChildren.get(+stage)?.stage.parent_id || 0;

                        if (participant) {
                            window.requestAnimationFrame((): void => {
                            // eslint-disable-next-line max-len
                                this.stageChildren.get(+stage)?.stageTableChildren.first?.openStageParticipant(categoryId, parentId, participant.id);
                            });
                        }
                    }
                    this.showSportMenu = true;
                }, 1000);
            }
            this.first = false;
        }
    }


    public ngOnDestroy(): void {
        if (this.subscriberTab instanceof Subscription) {
            this.subscriberTab.unsubscribe();
        }

        this.destroyAll();
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_sport_detail').subscribe((): void => {
            const sport = this.translate.instant(`sport.${this.sportName}`);
            const trans = this.translate.instant('web.title_sport_detail');
            const desc = this.translate.instant('web.description_sport_detail');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${sport} - ${trans} | ${client}`,
                    description: `${sport} ${desc}`,
                },
                true,
            );
        });
    }

    /**
     * Load data
     */
    private loader(): void {
        this.destroyAll();

        this.subscriberReload = timer(0, environment.stageListReloadTime)
            .pipe(switchMap((t): any => of(t)))
            .subscribe((): Promise<void> => this.load());
    }

    /**
     * Toggle aside menu
     * @return {void}
     */
    public toggleAssideLimit(): void {
        this.asideLimit =
            this.asideLimit === SPORT_CONFIG.asideSportDefaultNum ? 12 : SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Check if aside menu is closed
     * @return {boolean}
     */
    public isAsideDefault(): boolean {
        return this.asideLimit === SPORT_CONFIG.asideSportDefaultNum;
    }

    /**
     * Destroy all subscribers
     * @return {void}
     */
    private destroyAll(): void {
        this.data = [];
        clearInterval(this.firstInterval);
        if (this.subscriberReload instanceof Subscription) {
            this.subscriberReload.unsubscribe();
        }

        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    private destroyPart(): void {
        if (this.subscriber instanceof Subscription) {
            this.subscriber.unsubscribe();
        }
        if (this.subscriber2 instanceof Subscription) {
            this.subscriber2.unsubscribe();
        }
    }

    public trackByFn(index: number, item: Record<string, any>): number | null {
        if (isUndefined(item)) {
            return null;
        }
        return item.id;
    }

    /**
     * Date change event
     * @event MatchListComponent#dateChange
     * @param  date [description]
     * @return      [description]
     */
    public dateChange(date: any): void {
        this.date = date.format(TimeService.dateFormat);
        this.route.navigate(['page', 'stage', 'stage-list', `${this.sportName}-${this.sportId}`, { date: this.date }]);
    }

    public changeTab(tab: string = 'all'): void {
        this.type = tab;
        this.stageList.lastTab = tab;
        this.first = true;
        this.firstDefault = false;
        this.alphaFilter = undefined;
        this.reset();
        this.data = [];
        this.categoryData = {};
        this.categoryGroupData = {};
        this.alphaAvailLetters = [];
        this.loader();
        this.stageList.alphaReset.emit(true);

        if (this.isGroupCategory) {
            if (tab === 'live') {
                this.categoryGroup.forEach((v): void => {
                    v.isopen = true;
                });
            } else {
                this.categoryGroup.forEach((v): void => {
                    v.isopen = false;
                });
            }
        }
    }

    /**
     * Format UTC datetime to date dd.mm.YYYY
     * @param  {string} date
     * @return {string}
     */
    public setDate(date: string): string {
        const obj = new LiveDatePipe();
        return obj.transform(date, 'datedb');
    }

    /**
     * Check if data for suboturnamen texists
     * @param  {number} subTournamentId
     * @return    {MatchData[]}
     */
    public getData(subTournamentId: number): MatchData[] {
        return (this.categoryData && this.categoryData[subTournamentId]) || [];
    }

    /**
     * Event fired when some match is selected/clicked
     * @event MatchListComponent#loadMatch
     * @param  stage
     * @param  {boolean} updateCaption
     * @return {void}
     */
    public loadStage(stage: any, updateCaption: boolean = true): void {
        if (updateCaption) {
            this.detailCaption = '';
        }
        if (this.stageId != null && this.stageId === stage.stageId) {
            return;
        }

        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        this.matchClass['d-lg-block'] = true;
        this.stageId = stage.stageId;
        this.tracker = false;
    }

    /**
     *
     * @param data
     */
    public loadStageParticipant(data: any): void {
        this.parentClass['col-lg-8'] = true;
        this.parentClass['col-lg-12'] = false;
        this.matchClass['d-lg-block'] = true;
        this.stageParticipantStageId = data.stageId;
        this.stageParticipantParticipantId = data.participantId;
    }

    /**
     * Open default match on first load
     * @return {void}
     */
    private openDefault(): void {
        if (isUndefined(this.data[0])) {
            this.stageId = null;
            this.parentClass['col-lg-12'] = true;
            this.parentClass['col-lg-8'] = false;
            this.matchClass['d-lg-block'] = false;
        } else {
            this.parentClass['col-lg-8'] = true;
            this.parentClass['col-lg-12'] = false;
            this.matchClass['d-lg-block'] = true;


            // this.loadStage({ stageId: this.data[0].stages![0].category_id });
        }
    }

    /**
     * Check if there is no category data
     * @return [description]
     */
    public noCategory(): boolean {
        return !isArray(this.data);
    }

    public showMoreResults(): void {
        this.slice += environment.matchListMobileLimit;
    }

    /**
     * Chekc if there is more results to show
     * @return {boolean}
     */
    public isMoreResults(): boolean {
        return this.data.length > this.slice;
    }

    private filtrAlpha(data: any): Promise<string[]> {
        return new Promise((resolve): void => {
            const ret: string[] = [];
            if (isArray(data)) {
                data.forEach((v): void => {
                    if (SPORT_CONFIG.isSet(this.sportName)) {
                        if (v.sub_tournament_name !== null) {
                            ret.push(v.sub_tournament_name.charAt(0).toLowerCase());
                        }
                    } else if (v.category_name !== null) {
                        ret.push(v.category_name.charAt(0).toLowerCase());
                    }
                });
            }

            resolve(ret);
        });
    }

    private isCategoryOpen(subId: number): boolean {
        const openCategories = this.storage.get<any[]>(SPORT_CONFIG.categoryIndex) || [];

        const index = openCategories.findIndex((val): boolean => val === subId);

        return index !== -1;
    }

    /**
     * Manage which categories will be open in which situation
     */
    private handleOpenCategories(c: CategoryInterface, key: number): void {
        if (!this.deviceService.isMobile() && this.type !== 'live' && key < 4) {
            c.isopen = true;
        } else if (!this.deviceService.isMobile() && this.type === 'live' && key < 8) {
            c.isopen = true;
        } else if (this.deviceService.isMobile() && this.type !== 'live' && key < 1) {
            c.isopen = true;
        } else if (this.deviceService.isMobile() && this.type === 'live' && key < 4) {
            c.isopen = true;
        } else if (this.openAll) {
            c.isopen = true;
        } else {
            c.isopen = false;
        }

        if (this.isCategoryOpen(c.sub_tournament_id)) {
            c.isopen = true;
        } else if (this.favorite.existsTournament(this.sportId, c.tournament_id)) {
            c.isopen = true;
        }
    }

    public onOpenChange(event: any): void {
        // this.data = this.data.map(
        //     (val): CategoryInterface => {
        //         val.isopen = event;
        //         return val;
        //     }
        // );

        this.openAll = event;
        this.load(true);
    }

    /**
     * Map category to category groups
     * @param  {CategoryInterface[]} category
     * @return  {CategoryInterface[]}
     */
    private getCategoryGroup(category: CategoryInterface[]): CategoryInterface[] {
        const ret: any[] = [];
        category.forEach((cat): void => {
            const find = ret.find((val): boolean => val.category_id === cat.category_id);

            if (!find) {
                const found = this.categoryGroup.find((val2): boolean => val2.category_id === cat.category_id);

                const saved = this.storage.get<number[]>('APP_GROUP_MATCHES');
                const found2 = saved ? saved.includes(cat.category_id) : false;
                if (this.first && found2) {
                    cat.isopen = true;
                } else if (found) {
                    cat.isopen = found.isopen;
                } else {
                    cat.isopen = false;
                }
                ret.push(cat);
            }
        });
        return JSON.parse(JSON.stringify(ret));
    }

    /**
     * Load tournament categories
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected async load(updateAll: boolean = false,
        data: Observable<CategoryInterface[]> | null = null): Promise<any> {
        try {
            data = await this.stageList.getCategories(this.sportId, this.type, this.from, this.to);

            this.subscriber = data.subscribe(
                (val): any => {
                    // @ts-ignore
                    this.data = val;


                    this.openDefault();

                    // this.first = false;

                    this.finished(true);
                },
                (): void => {
                    if (this.first) {
                        this.error(true);
                    }
                },
            );
        } catch (e) {
            if (this.first) {
                this.error(true);
            }
        }
    }

    private openAtLastOne(): Promise<void | false> {
        return new Promise(
            (resolve): any => {
                if (this.data.length === 0) {
                    resolve(false);
                }
                resolve();
            },
        );
    }

    public openCategory(categoryId: number): void {
        this.stageList.toggleOpenCategory(categoryId);
    }

    public isCategoryClose(categoryId: number): boolean {
        return this.stageList.isCategoryClose(categoryId);
    }
}
