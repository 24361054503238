<div class="container bg-light position-relative">
    <div *ngIf="!deviceService.isMobile()" class="pt-3"></div>
    <div class="row">
        <div class="col-lg-8 bg-white p-0" [ngClass]="parentClass">
            <div class="bg-white p-3 position-relative">
                <div class="table-sport mx-n3 mx-sm-0">
                    <div *ngIf="loaded; else elseBlock" class="table-sport__content">
                        <ng-container *ngIf="loadedError; else loadBlock">
                            <div class="tab-pane">
                                <div class="text-center pt-7 app-match-no-data">
                                    <svg class="icon icon--64 mb-4">
                                        <use [attr.xlink:href]="'#icon-' + sportName" />
                                    </svg>
                                    <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                    <p class="mb-6">{{ 'web.error_data_load' | translate }}</p>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #loadBlock>
                            <ng-container *ngIf="!noCategory()">
                                <div *ngFor="let event of data" class="table-sport mx-n3 mx-sm-0 mb-3">
                                    <div
                                        *ngIf="!deviceService.isMobile()"
                                        class="media w-100 mb-4 flex-column flex-md-row"
                                    >
                                        <div class="mr-4 mb-1" *ngIf="event.eventStage_circuitImage">
                                            <img src="{{ event.eventStage_circuitImage }}" alt="Circuit" height="130" />
                                        </div>
                                        <div class="media-body">
                                            <h1 class="mb-2 d-flex align-items-center">
                                                {{ event.category_description }} - {{ event.eventStage_description }}
                                            </h1>
                                            <div class="row">
                                                <div *ngIf="event.eventStage_stageInfos?.venue?.name" class="col-12">
                                                    <strong>{{ 'web.motosport_stadium' | translate }}:</strong>
                                                    {{ event.eventStage_stageInfos.venue.name }}
                                                </div>
                                                <div *ngIf="event.eventStage_stageInfos?.venue?.city" class="col-12">
                                                    <strong>{{ 'web.city' | translate }}:</strong>
                                                    {{ event.eventStage_stageInfos.venue.city }},
                                                    {{ event.eventStage_stageInfos.venue.country }}
                                                </div>
                                                <div *ngIf="event.eventStage_stageInfos?.venue?.length" class="col-12">
                                                    <strong>{{ 'web.length' | translate }}:</strong>
                                                    {{ event.eventStage_stageInfos.venue.length | number }}
                                                    {{ 'web.meters' | translate }}
                                                </div>
                                                <div *ngIf="event.eventStage_stageInfos?.venue?.laps" class="col-12">
                                                    <strong>{{ 'web.laps' | translate }}:</strong>
                                                    {{ event.eventStage_stageInfos.venue.laps }}
                                                </div>
                                            </div>

                                            <ng-container *ngIf="event.stages[0]">
                                                <div class="row mt-2">
                                                    <div
                                                        *ngIf="event.stages[event.stages.length - 1].startDate"
                                                        class="col-12"
                                                    >
                                                        <strong>{{ 'web.start_time' | translate }}:</strong>
                                                        {{
                                                            event.stages[event.stages.length - 1].startDate
                                                                | livDate : 'localDateTime'
                                                        }}
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div *ngIf="deviceService.isMobile()">
                                        <div class="pl-5 pt-5 pr-5 pr-md-3 pb-3">
                                            <div class="media">
                                                <div *ngIf="event.eventStage_iso" class="mr-3">
                                                    <img
                                                        src="/img/flags/1x1/{{ event.eventStage_iso | lowercase }}.svg"
                                                        height="130"
                                                    />
                                                </div>
                                                <div class="media-body">
                                                    <div class="d-flex icon-wrapper justify-content-start flex-wrap">
                                                        <h1 class="mb-2">{{ event.eventStage_description }}</h1>
                                                    </div>
                                                    <div class="mb-2 h5 d-flex">
                                                        <span class="font-weight-bold mr-1">{{
                                                            event.category_description
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="nav-wrapper" style="position: relative">
                                            <div class="app-mob-nav-shadow"></div>
                                            <ul
                                                class="nav nav-tabs nav-tabs--shadows font-weight-bold pt-3"
                                                id="tabs-left"
                                                role="tablist"
                                            >
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link active px-3 px-md-5 active"
                                                        id="tab1"
                                                        data-toggle="tab"
                                                        href="#tab1-content"
                                                        role="tab"
                                                        aria-controls="tab1-content"
                                                        aria-selected="true"
                                                        >{{ 'web.race' | translate }}</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link px-3 px-md-5"
                                                        id="tab2"
                                                        data-toggle="tab"
                                                        href="#tab2-content"
                                                        role="tab"
                                                        aria-controls="tab2-content"
                                                        aria-selected="false"
                                                        >{{ 'web.stage_event_info' | translate }}</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        class="nav-link px-3 px-md-5"
                                                        id="tab3"
                                                        data-toggle="tab"
                                                        href="#tab3-content"
                                                        role="tab"
                                                        aria-controls="tab3-content"
                                                        aria-selected="false"
                                                        >{{ 'web.standings' | translate }}</a
                                                    >
                                                </li>
                                                <li *ngIf="event.eventStage_hasVideo" class="nav-item">
                                                    <a
                                                        class="nav-link px-2 px-md-5"
                                                        id="tab4"
                                                        data-toggle="tab"
                                                        href="#tab4-content"
                                                        role="tab"
                                                        aria-controls="tab4-content"
                                                        aria-selected="false"
                                                        >{{ 'web.video' | translate }}</a
                                                    >
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="tab-content bg-white p-3 position-relative">
                                            <div
                                                class="tab-pane active"
                                                id="tab1-content"
                                                role="tabpanel"
                                                aria-labelledby="tab1"
                                            >
                                                <app-stage
                                                    *ngFor="let stage of event.stages"
                                                    (onSelectStage)="loadStage($event)"
                                                    (onSelectStageParticipant)="loadStageParticipant($event)"
                                                    [stage]="stage"
                                                    [categoryName]="event.name"
                                                    [showDate]="true"
                                                    [sportId]="sportId"
                                                    [sportName]="sportName"
                                                ></app-stage>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="tab2-content"
                                                role="tabpanel"
                                                aria-labelledby="tab2"
                                            >
                                                <div class="row">
                                                    <div class="col">
                                                        <img
                                                            *ngIf="event.eventStage_circuitImage"
                                                            class="img-responsive mb-3"
                                                            src="{{ event.eventStage_circuitImage }}"
                                                            alt="Circuit"
                                                        />
                                                        <div class="table-responsive">
                                                            <table class="table table-sm stage-table">
                                                                <tbody>
                                                                    <tr *ngIf="event.eventStage_stageInfos?.venue?.name">
                                                                        <th class="text-nowrap">
                                                                            {{ 'web.motosport_stadium' | translate }}:
                                                                        </th>
                                                                        <td>{{ event.eventStage_stageInfos.venue.name }}</td>
                                                                    </tr>
                                                                    <tr *ngIf="event.eventStage_stageInfos?.venue?.city">
                                                                        <th class="text-nowrap">
                                                                            {{ 'web.city' | translate }}:
                                                                        </th>
                                                                        <td>
                                                                            {{ event.eventStage_stageInfos.venue.city }},
                                                                            {{ event.eventStage_stageInfos.venue.country }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="event.eventStage_stageInfos?.venue?.length">
                                                                        <th class="text-nowrap">
                                                                            {{ 'web.length' | translate }}:
                                                                        </th>
                                                                        <td>
                                                                            {{
                                                                                event.eventStage_stageInfos.venue.length
                                                                                    | number
                                                                            }}
                                                                            {{ 'web.meters' | translate }}
                                                                        </td>
                                                                    </tr>
                                                                    <tr *ngIf="event.eventStage_stageInfos?.venue?.laps">
                                                                        <th class="text-nowrap">
                                                                            {{ 'web.laps' | translate }}:
                                                                        </th>
                                                                        <td>{{ event.eventStage_stageInfos.venue.laps }}</td>
                                                                    </tr>
                                                                    <tr
                                                                        *ngIf="
                                                                            event.stages[0] &&
                                                                            event.stages[event.stages.length - 1]
                                                                                .startDate
                                                                        "
                                                                    >
                                                                        <th class="text-nowrap">
                                                                            {{ 'web.start_time' | translate }}:
                                                                        </th>
                                                                        <td>
                                                                            {{
                                                                                event.stages[event.stages.length - 1]
                                                                                    .startDate | livDate : 'localDateTime'
                                                                            }}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="tab-pane"
                                                id="tab3-content"
                                                role="tabpanel"
                                                aria-labelledby="tab3"
                                            >
                                                <app-stage-right-col
                                                    [sportId]="sportId"
                                                    [sportName]="sportName"
                                                    [standingsStageId]="stageId"
                                                    [stageParticipantStageId]="stageParticipantStageId"
                                                    [stageParticipantParticipantId]="stageParticipantParticipantId"
                                                ></app-stage-right-col>
                                            </div>

                                            <div
                                                *ngIf="event.eventStage_hasVideo"
                                                class="tab-pane"
                                                id="tab4-content"
                                                role="tabpanel"
                                                aria-labelledby="tab4"
                                            >
                                                <div
                                                    *ngFor="let stage of event.stages"
                                                    class="text-center app-stream-content"
                                                >
                                                    <app-youtube
                                                        *ngIf="stage?.youtube_video"
                                                        [video]="stage.youtube_video"
                                                        [videoImage]="stage?.youtube_preview"
                                                        [stageId]="stage.id"
                                                    ></app-youtube>
                                                    <app-twitch
                                                        *ngIf="stage?.twitch_video"
                                                        [video]="stage.twitch_video"
                                                    ></app-twitch>
                                                    <app-isport
                                                        *ngIf="stage?.isport_video"
                                                        [video]="stage.isport_video"
                                                    ></app-isport>
                                                    <app-vimeo
                                                        *ngIf="stage?.vimeo_video"
                                                        [video]="stage.vimeo_video"
                                                    ></app-vimeo>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="!deviceService.isMobile()">
                                        <app-stage
                                            *ngFor="let stage of event.stages"
                                            (onSelectStage)="loadStage($event)"
                                            (onSelectStageParticipant)="loadStageParticipant($event)"
                                            [stage]="stage"
                                            [categoryName]="event.name"
                                            [showDate]="true"
                                            [sportId]="sportId"
                                            [sportName]="sportName"
                                        ></app-stage>
                                    </div>
                                </div>

                                <ng-container *ngIf="!loadedError && data.length == 0">
                                    <div class="tab-pane">
                                        <div class="text-center pt-7 app-match-no-data">
                                            <svg class="icon icon--64 mb-4">
                                                <use [attr.xlink:href]="'#icon-' + sportName" />
                                            </svg>
                                            <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>
                                            <p class="mb-6">{{ 'web.no_data_found' | translate }}</p>
                                        </div>
                                    </div>
                                </ng-container>

                                <!-- <div class="d-flex justify-content-center py-2" *ngIf="isMoreResults()">
                                    <button type="button" class="btn btn-primary" (click)="showMoreResults()">{{ 'web.next_result' | translate }}</button>
                                </div> -->
                            </ng-container>

                            <ng-container *ngIf="noCategory()">
                                <div class="tab-pane">
                                    <div class="text-center pt-7 app-match-no-data">
                                        <svg class="icon icon--64 mb-4">
                                            <use [attr.xlink:href]="'#icon-' + sportName" />
                                        </svg>
                                        <h4 class="font-weight-bold">{{ 'web.we_are_sorry' | translate }}</h4>

                                        <ng-container *ngIf="type == 'live'; else noOtherData">
                                            <p class="mb-6">{{ 'web.no_live_tab_data_found' | translate }}</p>
                                        </ng-container>
                                        <ng-template #noOtherData>
                                            <p class="mb-6">{{ 'web.no_data_found' | translate }}</p>
                                        </ng-template>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </div>
                    <ng-template #elseBlock>
                        <app-page-loader></app-page-loader>
                    </ng-template>
                </div>

                <app-alpha-filter
                    *ngIf="alphaAvailLetters.length > 0"
                    class="d-none d-lg-block"
                    [availLetters]="alphaAvailLetters"
                    (onDataChange)="alphaFilter = $event"
                ></app-alpha-filter>
            </div>
        </div>
        <div class="col-lg-4 pl-3 pr-3 d-none d-lg-block" [ngClass]="matchClass">
            <ng-container *ngIf="!deviceService.isMobile()">
                <div [class.sticky-parent]="stickyDetail">
                    <app-stage-right-col
                        [sportId]="sportId"
                        [sportName]="sportName"
                        [standingsStageId]="stageId"
                        [stageParticipantStageId]="stageParticipantStageId"
                        [stageParticipantParticipantId]="stageParticipantParticipantId"
                    ></app-stage-right-col>
                </div>
            </ng-container>
        </div>
    </div>

    <aside class="nav-sports d-none d-xl-block">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation
                type="aside"
                [date]="paramDate"
                [disabled]="this.first"
                [limit]="asideLimit"
                [sportId]="sportId"
            ></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset app-cursor"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
</div>
