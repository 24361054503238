<ng-container *ngIf="standingsData; else elseBlock">
    <ng-container
        *ngIf="
            standingsData[0] &&
            standingsData[0].valueTypes &&
            standingsData[0].valueTypes.competitors
        "
    >
        <div class="match-info-head text-white mb-0 bg-dark p-2 d-flex">
            <span class="font-weight-bold text-uppercase"
                >{{ 'web.seasonal-statistics' | translate }} {{ standingsData[0].description }}</span
            >
        </div>
        <div class="mb-3 bg-white">
            <div class="collapse show" id="collapse-drivers">

                <div class="table-responsive">
                    <table class="table table-sm stage-table">
                        <thead>
                            <tr class="bg-light2">

                                <td class="small align-middle">{{ 'web.year' | translate }}</td>
                                <td class="small align-middle">#</td>
                                <ng-container *ngFor="let valueType of standingsData[0].valueTypes.competitors">
                                    <td class="small align-middle">{{ 'stagevaluetype.' + standingsData[0].category + '_' + valueType | translate }}</td>
                                </ng-container>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let row of standingsData">

                                <td class="align-middle">
                                    {{ row.year }}
                                </td>
                                <td class="align-middle">
                                    <ng-container *ngIf="row.position"
                                    >{{ row.position }}.</ng-container
                                    >
                                </td>
                                <ng-container *ngFor="let valueType of row.valueTypes.competitors">
                                    <td class="align-middle">
                                        <ng-container *ngIf="row.values[valueType]; else elseVal">
                                            {{ row.values[valueType] }}
                                        </ng-container>
                                        <ng-template #elseVal>-</ng-template>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </ng-container>


</ng-container>
<ng-template #elseBlock>
    <app-page-loader></app-page-loader>
</ng-template>
