import { Component, Input } from '@angular/core';
import { PageService } from '@services/page.service';
import { StageListService } from '@services/stage-list.service';

@Component({
    selector: 'app-stage-participant',
    templateUrl: './stage-participant.component.html',
})
export class StageParticipantComponent {
    @Input()
    public sportId: number | null = null;

    @Input()
    public sportName: string;

    @Input()
    public stageParticipantData: any;

    public constructor(
        public stageListService: StageListService,
        public pageService: PageService,
    ) {
    }
}
