import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ComponentModule } from '@components/component.module';
import { StageEventParticipantComponent }
    from '@pages/page-stage/stage-event-participant/stage-event-participant.component';
import { PageStageComponent } from '@pages/page-stage/page-stage.component';
import { StageListComponent } from '@pages/page-stage/stage-list/stage-list.component';
import { StageEventComponent } from '@pages/page-stage/stage-event/stage-event.component';

import { StageRoutingModule } from './stage-routing.module';

import { SharedModule } from '@/shared/shared.module';

@NgModule({
    declarations: [StageEventParticipantComponent, PageStageComponent, StageListComponent, StageEventComponent],
    imports: [CommonModule, SharedModule, StageRoutingModule, ComponentModule],
})
export class StageModule {}
