import { Component, Input } from '@angular/core';
import { StageStandings } from '@interfaces/stage.interface';

@Component({
    selector: 'app-stage-standings',
    templateUrl: './stage-standings.component.html',
})
export class StageStandingsComponent {
    @Input()
    public sportId: number | null = null;

    @Input()
    public sportName: string;

    @Input()
    public standingsData: StageStandings | null = null;


    isUndefined(): boolean {
        return typeof this.standingsData === 'undefined';
    }
}
