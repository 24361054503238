import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StageEventParticipantComponent }
    from '@pages/page-stage/stage-event-participant/stage-event-participant.component';
import { PageStageComponent } from '@pages/page-stage/page-stage.component';
import { StageListComponent } from '@pages/page-stage/stage-list/stage-list.component';
import { StageEventComponent } from '@pages/page-stage/stage-event/stage-event.component';
import { GolfParticipantComponent } from '@pages/page-golf/golf-participant/golf-participant.component';
import { StageParticipantComponent } from "@pages/page-stage/stage-participant/stage-participant.component";


const routes: Routes = [
    {
        path: '',
        component: PageStageComponent,
        children: [
            {
                path: 'stage-list/:sport',
                component: StageListComponent,
            },
            {
                path: 'participant/:sport/:id',
                component: StageParticipantComponent,
            },
            {
                path: 'event/:sport/:event',
                component: StageEventComponent,
            },
            {
                path: 'event-participant/:sport/:root-stage/:event/:participant',
                component: StageEventParticipantComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class StageRoutingModule {}
