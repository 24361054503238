import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { StageListService } from '@services/stage-list.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StageCategory } from '@/interfaces/stage.interface';

import { LangService } from '../../../services/lang.service';
import { ClientService } from '../../../services/client.service';
import { BaseComponent } from '../../base.component';
import { PageService } from '../../../services/page.service';
import { InfoService } from '../../../services/info.service';

@Component({
    selector: 'app-match',
    templateUrl: './stage-event-participant.component.html',
})
export class StageEventParticipantComponent extends BaseComponent implements OnInit {
    public categoryId: number | null = null;

    public eventStageId: number | null = null;

    public participantId: number | null = null;

    public stageParticipantData: StageCategory[];

    public standingsData: any;

    public constructor(
        protected page: PageService,
        private router: ActivatedRoute,
        protected title: Title,
        protected meta: Meta,
        protected route: Router,
        protected translate: TranslateService,
        protected client: ClientService,
        protected lang: LangService,
        protected info: InfoService,
        public location: Location,
        private stageListService: StageListService,
        public deviceService: DeviceDetectorService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.router.paramMap.subscribe((params): void => {
            this.categoryId = +params.get('root-stage')!;
            this.eventStageId = +params.get('event')!;
            this.participantId = +params.get('participant')!;
            $(window).scrollTop(0);
            this.loadedData();
        });
    }

    public async loadedData(): Promise<any> {
        const data = await this.stageListService.getStageEventParticipant(this.eventStageId, this.participantId);

        data.subscribe((dataVal) => {
            this.stageParticipantData = dataVal;
        });

        const data2 = await this.stageListService.getStageStandings(this.categoryId);

        data2.subscribe((standings) => {
            this.standingsData = standings;
        });
    }
}
