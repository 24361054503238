<div *ngIf="videoStage">
    <div class="text-center app-stream-content">
        <app-youtube
            *ngIf="videoStage?.youtube_video"
            [video]="videoStage.youtube_video"
            [videoImage]="videoStage?.youtube_preview"
            [stageId]="videoStage.id"
        ></app-youtube>
        <app-twitch
            *ngIf="videoStage?.twitch_video"
            [video]="videoStage.twitch_video"
        ></app-twitch>
        <app-isport
            *ngIf="videoStage?.isport_video"
            [video]="videoStage.isport_video"
        ></app-isport>
        <app-vimeo
            *ngIf="videoStage?.vimeo_video"
            [video]="videoStage.vimeo_video"
        ></app-vimeo>
    </div>
</div>

<app-stage-participant [stageParticipantData]="stageParticipantData" [sportId]="sportId" [sportName]="sportName"></app-stage-participant>

<app-stage-standings [standingsData]="standingsData" [sportId]="sportId" [sportName]="sportName" class="app-stage-standings"></app-stage-standings>

<ng-container *ngIf="[59, 97].includes(sportId)">
    <div class="golf-aside">
        <div class="bg-white">
                <img
                    [src]="'/img/sport/' + sportName + '-' + sportId + '.jpg'"
                    alt=""
                    class="img-fluid img-cover"
                    style="width: 100%; height: 40vh;"
                />
            <div class="d-flex flex-row align-items-center m-3 position-relative text-justify"
                 [innerHTML]="'web.footer_text_' + sportName | translate | markdown">
            </div>
        </div>
    </div>
</ng-container>
