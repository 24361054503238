import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { Stage } from '@interfaces/stage.interface';
import { BaseComponent } from '@components/base.component';
import { StageListService } from '@services/stage-list.service';

@Component({
    selector: 'app-stage-table',
    templateUrl: './stage-table.component.html',
})
export class StageTableComponent extends BaseComponent {
    @Input()
    public participants: any; // stage.participants[1], stage.participants[2]

    @Input()
    public valueTypes: any; // stage.valueTypes.OutrightCompetitorValueTypes, stage.valueTypes.OutrightTeamValueTypes

    @Input()
    public stage: Stage;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    public constructor(
        public stageListService: StageListService,
        public deviceService: DeviceDetectorService,
        private router: Router,
    ) {
        super();
    }

    public openStageParticipant(parentStageId: number, stageId: number, participantId: number): void {
        // TODO temporary disable for horses and greyhounds
        if ([59, 97].includes(this.sportId)) {
            return;
        }
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            this.router.navigate([
                `/page/stage/participant/${this.sportName}-${this.sportId}`,
                participantId,
            ]);
        } else {
            this.stageListService.stageParticipantOpener$.next({ stageId, participantId });
        }
    }
}
