import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { BaseComponent } from '@components/base.component';
import { Stage } from '@interfaces/stage.interface';
import { PageService } from '@services/page.service';
import { StageListService } from '@services/stage-list.service';
import { WindowRefService } from '@shared/window-ref';

import { StageTableComponent } from './stage-table/stage-table.component';

@Component({
    selector: 'app-stage',
    templateUrl: './stage.component.html',
})
export class StageComponent extends BaseComponent implements OnInit {
    @ViewChildren(StageTableComponent) public stageTableChildren!: QueryList<StageTableComponent>;

    @Input()
    public stage: Stage;

    @Input()
    public categoryName: string;

    @Input()
    public sportName: string;

    @Input()
    public sportId: number;

    @Input()
    public showDate = false;

    @Input()
    public showEventDetailBtn = false;

    @Input()
    public isFirst = false;

    @Output()
    public onSelectStage: EventEmitter<Record<string, any>> = new EventEmitter();


    public constructor(
        private stageList: StageListService,
        public deviceService: DeviceDetectorService,
        private winRef: WindowRefService,
        private router: Router,
        public pageService: PageService,
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.isFirst && this.stage.hasVideo && !this.deviceService.isMobile()) {
            this.openVideo(null, this.stage);
        }
    }

    public openStage(stageId: number): void {
        this.winRef.nativeWindow.requestAnimationFrame((): void => {
            this.stageList.toggleOpenStage(stageId);
        });

        this.onSelectStage.emit({ stageId: this.stage.category_id });
    }

    public openVideo(e: Event | null, stage: Stage): void {
        this.stageList.stageVideoOpener$.next(stage);

        if (e !== null) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    public isStageOpen(stageId: number): boolean {
        return this.stageList.isStageOpen(stageId);
    }

    public closeAllStages(): void {
        this.stageList.closeAllStages();
    }
}
