<div class="container bg-light position-relative">
    <div class="row">
        <div class="col-lg-8 bg-white p-0" [ngClass]="parentClass">
            <div class="nav-wrapper app-mob-nav-wrapper">
                <div class="app-mob-nav-shadow"></div>
                <ul
                    class="nav nav-tabs nav-tabs--shadows nav-justified--mobile font-weight-bold"
                    id="tabs-left"
                    role="tablist"
                >
                    <!-- <li class="nav-item" *ngIf="deviceService.isMobile()">
                        <a
                            class="nav-link px-3 px-md-4 active"
                            (click)="changeTab('all')"
                            [class.active]="type == 'all'"
                            id="tab1"
                            data-toggle="tab"
                            href="#tab1-content"
                            role="tab"
                            aria-controls="tab1-content"
                            aria-selected="true"
                            >{{ 'web.all_matches' | translate }}</a
                        >
                    </li> -->
                    <!--                <li class="nav-item">-->
                    <!--                  <a class="nav-link px-3 px-md-4" (click)="changeTab('live')" [class.active]="type == 'live'" id="tab2" data-toggle="tab" href="#tab2-content" role="tab" aria-controls="tab2-content" aria-selected="false">{{ 'web.live_matches' | translate }}</a>-->
                    <!--                </li>-->
                    <!--                <li class="nav-item ">-->
                    <!--                  <a class="nav-link px-3 px-md-4" (click)="changeTab('finished')" [class.active]="type == 'finished'" id="tab3" data-toggle="tab" href="#tab3-content" role="tab" aria-controls="tab3-content" aria-selected="false">{{ 'web.finished_matches' | translate }}</a>-->
                    <!--                </li>-->
                    <!--                <li class="nav-item">-->
                    <!--                  <a class="nav-link px-3 px-md-4" (click)="changeTab('not_started')" [class.active]="type == 'not_started'" id="tab4" data-toggle="tab" href="#tab4-content" role="tab" aria-controls="tab4-content" aria-selected="false">{{ 'web.future_matches' | translate }}</a>-->
                    <!--                </li>-->
                    <li
                        *ngIf="!deviceService.isMobile()"
                        class="nav-item ml-auto bg-white d-flex align-items-center px-2 app-mob-calendar"
                    >
                        <app-calendar class="d-flex align-items-center" (onChange)="dateChange($event)"></app-calendar>
                    </li>
                </ul>
            </div>
            <div class="d-md-none mb-2 dropdown-calendar">
                <app-calendar [mobile]="true" (onChange)="dateChange($event)"></app-calendar>
            </div>
            <div class="tab-content bg-white p-3 position-relative">
                <div class="tab-pane active" id="tab1-content" role="tabpanel" aria-labelledby="tab1">
                    <div class="table-sport mx-n3 mx-sm-0">
                        <div *ngIf="loaded; else elseBlock" class="table-sport__content">
                            <ng-container *ngIf="loadedError; else loadBlock">
                                <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                    <div class="text-center pt-7 app-match-no-data">
                                        <svg class="icon icon--64 mb-4">
                                            <use [attr.xlink:href]="'#icon-' + sportName" />
                                        </svg>
                                        <h4 class="font-weight-bold">{{ 'web.error_data_load' | translate }}</h4>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-template #loadBlock>
                                <ng-container *ngIf="!noCategory()">
                                    <div
                                        *ngFor="let category of data; first as isFirst"
                                        class="table-sport mx-n3 mx-sm-0 mb-3"
                                    >
                                        <h6
                                            class="table-sport__head bg-dark text-white"
                                            (click)="openCategory(category.id)"
                                            data-toggle="collapse"
                                            [class.collapsed]="isCategoryClose(category.id)"
                                            [attr.data-target]="'#collapse-category-' + category.id"
                                            role="button"
                                            [attr.aria-expanded]="!isCategoryClose(category.id)"
                                            [attr.aria-controls]="'collapse-category-' + category.id"
                                        >
                                            <img src="{{ category.image }}" alt="" width="20" class="mr-1" />
                                            <span class="table-sport__head__text">{{ category.name }}</span>
                                        </h6>

                                        <div
                                            class="table-sport__content collapse"
                                            [class.show]="!isCategoryClose(category.id)"
                                            [attr.id]="'collapse-category-' + category.id"
                                        >
                                            <app-stage
                                                *ngFor="let stage of category.stages"
                                                (onSelectStage)="loadStage($event)"
                                                [showEventDetailBtn]="true"
                                                [stage]="stage"
                                                [categoryName]="category.name"
                                                [sportId]="sportId"
                                                [sportName]="sportName"
                                                [isFirst]="isFirst"
                                            ></app-stage>
                                        </div>
                                    </div>

                                    <!--                     <ng-container *ngIf="isGroupCategory; else normalGroup">-->
                                    <!--                         <app-category-group *ngFor="let group of categoryGroup; trackBy: trackByIndex" [(group)]="group" >-->
                                    <!--                             <ng-container *ngIf="group?.isopen && !loadedError ">-->
                                    <!--                                  <app-component-category  *ngFor="let cg of categoryGroupData[group.category_id] | navSearch:'alpha': alphaFilter:sportId:sportName | slice:0:slice; trackBy: trackByFn" [cg]="cg"-->
                                    <!--                                  [showCategoryName]="false" [sportId]="sportId" [sportName]="sportName" (cgChange)="load(true)">-->
                                    <!--                                            <ng-container *ngIf="cg?.isopen && !loadedError ">-->
                                    <!--                                                <app-component-match  *ngFor="let match of categoryData[cg.sub_tournament_id]; trackBy: trackByFn"-->
                                    <!--                                                [match]="match" [sportName]="sportName" [sportId]="sportId" (onSelect)="loadMatch($event)" [dateFormat2]="'time'" [makeNoise]="type == 'live' ? true : false">-->
                                    <!--                                                </app-component-match>-->
                                    <!--                                                <div *ngIf=" (categoryData && !categoryData[cg.sub_tournament_id]) || (categoryData &&  categoryData[cg.sub_tournament_id].length == 0)">-->
                                    <!--                                                     <app-placeholder-match></app-placeholder-match>-->
                                    <!--                                                </div>-->
                                    <!--                                            </ng-container>-->

                                    <!--                                  </app-component-category>-->
                                    <!--                              </ng-container>-->
                                    <!--                          </app-category-group>-->
                                    <!--                      </ng-container>-->
                                    <!--                      <ng-template #normalGroup>-->
                                    <!--                         <app-component-category  *ngFor="let cg of data | navSearch:'alpha': alphaFilter:sportId:sportName | slice:0:slice; trackBy: trackByFn" [cg]="cg" [sportId]="sportId" [sportName]="sportName" (cgChange)="load(true)">-->
                                    <!--                                    <ng-container *ngIf="cg?.isopen && !loadedError ">-->
                                    <!--                                        <app-component-match  *ngFor="let match of categoryData[cg.sub_tournament_id]; trackBy: trackByFn"-->
                                    <!--                                        [match]="match" [sportName]="sportName" [sportId]="sportId" (onSelect)="loadMatch($event)" [dateFormat2]="'time'" [makeNoise]="type == 'live' ? true : false">-->
                                    <!--                                        </app-component-match>-->
                                    <!--                                        <div *ngIf=" (categoryData && !categoryData[cg.sub_tournament_id]) || (categoryData &&  categoryData[cg.sub_tournament_id].length == 0)">-->
                                    <!--                                             <app-placeholder-match></app-placeholder-match>-->
                                    <!--                                        </div>-->
                                    <!--                                    </ng-container>-->

                                    <!--                          </app-component-category>-->
                                    <!--                      </ng-template>-->

                                    <ng-container *ngIf="!loadedError && data.length == 0">
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7 app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">
                                                    {{ 'web.no_races_data_found' | translate }}
                                                </h4>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- <div class="d-flex justify-content-center py-2" *ngIf="isMoreResults()">
                          <button type="button" class="btn btn-primary" (click)="showMoreResults()">{{ 'web.next_result' | translate }}</button>
                      </div> -->
                                </ng-container>

                                <ng-container *ngIf="noCategory()">
                                    <ng-container *ngIf="type == 'all'; else noData">
                                        <div
                                            class="tab-pane mb-5"
                                            id="tab2-content"
                                            role="tabpanel"
                                            aria-labelledby="tab2"
                                        >
                                            <div class="text-center pt-7 app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">
                                                    {{ 'web.no_races_data_found' | translate }}
                                                </h4>
                                                <a
                                                    *ngIf="data.latest"
                                                    [routerLink]="['./', { date: setDate(data.latest) }]"
                                                    class="btn btn-primary mx-2 py-1 px-4"
                                                    ><span class="d-block small mb-n1"
                                                        >{{ 'web.latest_event' | translate }}
                                                    </span>
                                                    {{ data.latest | livDate : 'localDate' }}</a
                                                >
                                                <a
                                                    *ngIf="data.upcoming"
                                                    [routerLink]="['./', { date: setDate(data.upcoming) }]"
                                                    class="btn btn-primary mx-2 py-1 px-4"
                                                    ><span class="d-block small mb-n1">{{
                                                        'web.upcomming_event' | translate
                                                    }}</span>
                                                    {{ data.upcoming | livDate : 'localDate' }}</a
                                                >
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noData>
                                        <div class="tab-pane" id="tab3-content" role="tabpanel" aria-labelledby="tab3">
                                            <div class="text-center pt-7 app-match-no-data">
                                                <svg class="icon icon--64 mb-4">
                                                    <use [attr.xlink:href]="'#icon-' + sportName" />
                                                </svg>
                                                <h4 class="font-weight-bold">
                                                    {{ 'web.no_races_data_found' | translate }}
                                                </h4>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ng-container>
                            </ng-template>
                        </div>
                        <ng-template #elseBlock>
                            <app-page-loader></app-page-loader>
                        </ng-template>
                    </div>

                    <app-alpha-filter
                        *ngIf="alphaAvailLetters.length > 0"
                        class="d-none d-lg-block"
                        [availLetters]="alphaAvailLetters"
                        (onDataChange)="alphaFilter = $event"
                    ></app-alpha-filter>
                </div>

                <div
                    *ngIf="('sport-info.' + sportName | translate).search('sport-info.' + sportName) == -1"
                    class="card shadow border-0 mt-5"
                >
                    <div class="app-info-card card-body p-0 p-md-5">
                        <div class="media">
                            <svg class="icon icon--32 mr-3">
                                <use href="#icon-bulp-2" />
                            </svg>
                            <div
                                class="media-body mt-2"
                                [innerHTML]="'sport-info.' + sportName | translate | markdown"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 p-3 d-none d-lg-block" [ngClass]="matchClass">
            <ng-container *ngIf="!deviceService.isMobile()">
                <!--            <app-match-detail  class="sticky-parent__match" [class.app-match-detail&#45;&#45;shadow]="stickyDetail" [style.max-height]="viewPortHeightMatchDetail" [id]="matchId" [tracker]="tracker"></app-match-detail>-->
                <app-stage-right-col
                    [sportId]="sportId"
                    [sportName]="sportName"
                    [standingsStageId]="stageId"
                    [stageParticipantStageId]="stageParticipantStageId"
                    [stageParticipantParticipantId]="stageParticipantParticipantId"
                ></app-stage-right-col>
            </ng-container>
        </div>
    </div>

    <aside class="nav-sports d-none d-xl-block" *ngIf="showSportMenu">
        <nav class="nav flex-column bg-light">
            <app-sport-navigation
                type="aside"
                [date]="paramDate"
                [disabled]="this.first"
                [limit]="asideLimit"
                [sportId]="sportId"
            ></app-sport-navigation>
            <a
                class="nav-link nav-link--more text-uppercase text-reset app-cursor"
                [class.nav-link--more]="isAsideDefault()"
                [class.nav-link--less]="!isAsideDefault()"
                (click)="toggleAssideLimit()"
            >
                <span *ngIf="isAsideDefault()">{{ 'web.more' | translate }}</span>
                <span *ngIf="!isAsideDefault()">{{ 'web.less' | translate }}</span>
            </a>
        </nav>
    </aside>
</div>
