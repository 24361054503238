/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Match data interfaces
 *  @author Livescore <live@score-stats.com>
 *  @copyright 2019 livescore
 */

export enum StageState {
    CLOSED = 'Closed',
    CANCELED = 'Cancelled',
}

export interface Stage {
    id: number;
    betradar_id: number;
    description?: string;
    parent_id?: number;
    parent_iso?: string;
    parent_iso3?: string;
    parent_description?: string;
    category_id?: number;
    category_description?: string;
    hasTeams: boolean;
    expand: boolean;
    stageType: number;
    stageTypeLabel: string;
    startDate?: string;
    endDate?: string;
    status?: string;
    hasVideo?: boolean;
    youtube_video?: string;
    youtube_preview?: string;
    twitch_video?: string;
    tvcom_video?: string;
    isport_video?: string;
    vimeo_video?: string;
    valueTypes?: ValueTypes;
    category?: string;
    participants: any[];
    childStages: any[];
    fastestLaps: string[];
    stageInfos: any;
    shirts?: string;
}
export interface StageCategory {
    id: number;
    name: string;
    image: string;
    category_description?: string;
    eventStage_description?: string;
    eventStage_circuitImage?: string;
    eventStage_hasVideo?: boolean;
    stages?: Stage[];
}

export interface StageStandings {
    [propr: string]: any;
    description?: string;
    valueTypes?: ValueTypes;
    category?: string;
    participants: [];
}

export interface StageSeasonStats {
    description?: string;
    year?: string;
    position?: number;
    valueTypes?: ValueTypes;
    category?: string;
    values: any;
}

export interface ValueTypes {
    competitors?: string[];
    teams?: string[];
}
