import { Component, Input } from '@angular/core';
import { StageSeasonStats } from '@interfaces/stage.interface';

@Component({
    selector: 'app-stage-seasons-stats',
    templateUrl: './stage-seasons-stats.component.html',
})
export class StageSeasonsStatsComponent {
    @Input()
    public sportId: number | null = null;

    @Input()
    public sportName: string;

    @Input()
    public standingsData: StageSeasonStats[];
}
