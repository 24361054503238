<div class="d-flex table-scrollable">
    <div class="table-scrollable__fixed">
        <div class="table-scrollable__fixed-shadow"></div>
        <table class="table-scrollable__fixed__table stage-table">
            <thead>
                <tr class="bg-light">
                    <th class="table-scrollable__table-th font-weight-normal text-center"><br />#</th>
                    <th class="table-scrollable__table-th font-weight-normal text-center">&nbsp;</th>
                    <th class="table-scrollable__table-th table-scrollable__fixed__table__text font-weight-normal">
                        <br />
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="table-scrollable__table-row" [class.app-cursor]="![59, 97].includes(sportId)"
                    *ngFor="let participant of participants; trackBy: trackByIndex"
                    (click)="openStageParticipant(stage.category_id, stage.parent_id, participant.id)"
                >
                    <td class="table-scrollable__table-td table-scrollable__table-place">
                        <span
                            *ngIf="participant.position; else elsePosition"
                            class="span"
                            [ngClass]="{
                                'medal-gold': participant.values['gold'],
                                'medal-silver': participant.values['silver'],
                                'medal-bronze': participant.values['bronze']
                            }"
                            >{{ participant.position }}</span
                        ><ng-template #elsePosition>
                            <span class="span" *ngIf="participant.values['dnf']">DNF</span>
                            <span class="span" *ngIf="participant.values['dns']">DNS</span>
                            <span class="span" *ngIf="participant.values['dsq']">DSQ</span>
                        </ng-template>
                    </td>
                    <td class="table-scrollable__table-td text-center">
                        <img
                            *ngIf="participant.iso"
                            src="/img/flags/1x1/{{ participant.iso | lowercase }}.svg"
                            alt=""
                            class="rounded-circle mr-2"
                            height="20"
                        />
                        <img
                            *ngIf="!participant.iso && ![59, 97].includes(sportId)"
                            src="/img/default-avatar.png"
                            alt=""
                            class="rounded-circle mr-2"
                            height="20"
                        />
                    </td>
                    <td class="table-scrollable__table-td table-scrollable-team">
                        {{ participant.name_short }}
                        <ng-container *ngIf="stage.shirts && false">
                            <img *ngIf="participant.values['climber_ranking'] == 1" class="ml-1" height="20"
                                 src="{{ stage.shirts }}/climber.png"
                                 data-toggle="tooltip" data-placement="top"
                                 title="{{ 'web.cycling-climber' | translate }}"
                            >
                            <img *ngIf="participant.values['sprint_ranking'] == 1" class="ml-1" height="20"
                                 src="{{ stage.shirts }}/sprint.png"
                                 data-toggle="tooltip" data-placement="top"
                                 title="{{ 'web.cycling-sprinter' | translate }}"
                            >
                            <img *ngIf="participant.values['young_rider_ranking'] == 1" class="ml-1" height="20"
                                 src="{{ stage.shirts }}/young_rider.png"
                                 data-toggle="tooltip" data-placement="top"
                                 title="{{ 'web.cycling-young-rider' | translate }}"
                            >
                        </ng-container>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="table-scrollable__data flex-grow-1" tableScroll>
        <table class="table-scrollable__data__table stage-table">
            <thead>
                <tr class="bg-light">
                    <th
                        *ngIf="stage.hasTeams"
                        class="table-scrollable__table-th table-scrollable__data__table-th text-left"
                    >
                        <br />{{ 'web.team' | translate }}
                    </th>
                    <ng-container *ngFor="let childStage of stage.childStages; trackBy: trackByIndex">
                        <ng-container
                            *ngFor="let valueType of childStage.valueTypes.competitors; trackBy: trackByIndex"
                        >
                            <th
                                *ngIf="!['dnf', 'dns', 'dsq'].includes(valueType)"
                                class="table-scrollable__table-th table-scrollable__data__table-th"
                            >
                                {{ childStage?.description }}<br />
                                {{ 'stagevaluetype.' + stage.category + '_' + valueType | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let childStage of stage.childStages">
                        <ng-container *ngFor="let valueType of childStage.valueTypes.teams">
                            <th
                                *ngIf="!['dnf', 'dns', 'dsq'].includes(valueType)"
                                class="table-scrollable__table-th table-scrollable__data__table-th"
                            >
                                {{ childStage?.description }}<br />
                                {{ 'stagevaluetype.' + stage.category + '_' + valueType | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let valueType of valueTypes">
                        <th
                            *ngIf="!['gold', 'silver', 'bronze'].includes(valueType)"
                            class="table-scrollable__table-th table-scrollable__data__table-th"
                        >
                            <br />{{ 'stagevaluetype.' + valueType | translate }}
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <ng-container>
                    <tr
                        class="table-scrollable__table-row stage-table-participant"
                        [class.app-cursor]="![59, 97].includes(sportId)"
                        *ngFor="let participant of participants; trackBy: trackByIndex"
                        (click)="openStageParticipant(stage.category_id, stage.parent_id, participant.id)"
                    >
                        <td
                            *ngIf="stage.hasTeams"
                            class="table-scrollable__table-td table-scrollable__data__table-td text-left"
                        >
                            {{ participant.team_name }}
                        </td>
                        <ng-container *ngFor="let childStage of stage.childStages; index as i">
                            <ng-container *ngFor="let valueType of childStage?.valueTypes?.competitors ?? []">
                                <td
                                    *ngIf="!['dnf', 'dns', 'dsq'].includes(valueType)"
                                    class="table-scrollable__table-td table-scrollable__data__table-td"
                                >
                                    {{ stage?.childStages[i]?.participants[participant.id]?.values[valueType] }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let childStage of stage.childStages; index as i">
                            <ng-container *ngFor="let valueType of childStage?.valueTypes?.teams ?? []">
                                <td
                                    *ngIf="!['dnf', 'dns', 'dsq'].includes(valueType)"
                                    class="table-scrollable__table-td table-scrollable__data__table-td"
                                >
                                    {{ stage?.childStages[i]?.participants[participant.id]?.values[valueType] }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let valueType of valueTypes">
                            <td
                                *ngIf="!['gold', 'silver', 'bronze'].includes(valueType)"
                                class="table-scrollable__table-td table-scrollable__data__table-td"
                            >
                                <ng-container
                                    *ngIf="
                                        stageListService.translatableValues.includes(participant.values[valueType]);
                                        else rawValueType
                                    "
                                >
                                    {{
                                        'web.stagevalue_' + participant.values[valueType] | lowercase | translate
                                    }} </ng-container
                                ><ng-template #rawValueType>
                                    {{ participant.values[valueType] }}
                                </ng-template>
                                <i
                                    *ngIf="
                                        valueType === 'fastest_lap_time' &&
                                        stage.fastestLaps.length &&
                                        stage.fastestLaps[0] === participant.values[valueType]
                                    "
                                    class="fas fa-stopwatch fastest-lap"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="{{ 'web.fastest_lap' | translate }}"
                                ></i>
                            </td>
                        </ng-container>

                        <!--                        <ng-container *ngFor="let childStage of stage.childStages">-->
                        <!--                            <ng-container *ngFor="let value of childStage?.participants[participant.id]?.values ?? {} | keyvalue">-->
                        <!--                                <td class="table-scrollable__table-td table-scrollable__data__table-td">-->
                        <!--                                    {{ value.key }}:{{ value.value }}-->
                        <!--                                </td>-->
                        <!--                            </ng-container>-->
                        <!--                        </ng-container>-->
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
